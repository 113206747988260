@media (max-width: 726px) {
    .heading {
        font-size: 2em !important;
    }

    /* .description {
        font-size: 5vh!important;
    } */
}

.container {
    width: 75%;
    height: 75%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}

.heading {
    font-size: 80px;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
    margin-top: 25px;
    margin-bottom: 0;
}

.description {
    font-size: 20px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
    margin-top: 15px;
}
