.video {
    position: relative;
    background-color: black;
    width: 100%;
    height: 100%;
    scroll-snap-align: start;

}

.video__wrap {
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
}

.video__player{
    object-fit: fill;
    width: 100%;
    height: auto;
}