.pratikChandoreWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 50px;

    /* height: 100vh;
    background-color: #f0f0f0; */
}

.QandAWrapper {
    /* display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 50px; */

    color: black;
    text-align: left; /* Center the content inside the div */
    padding: 20px;
    background-color: #ffffff; /* Background color for the contact info div */
    border-radius: 8px; /* Add rounded corners for a nicer look */
}



.footer-container {
    display: flex;
    justify-content: center; /* Horizontal centering */
    align-items: center; /* Vertical centering */
    /* height: 100vh; Ensure it takes up the full viewport height */
    /* background-color: #f0f0f0; Background color for the container */
    margin-top: 20px;
  }
  
  .contact-info {
    text-align: center; /* Center the content inside the div */
    padding: 20px;
    background-color: #ffffff; /* Background color for the contact info div */
    border-radius: 8px; /* Add rounded corners for a nicer look */
  }